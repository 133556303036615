// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  tokenIndex: 'callwaiter_token',
  currentUserIndex: 'callwaiter_current_user',
  profileCompletedIndex: 'callwaiter_profile_completed',
  powertable : true,
  tokenPrefix: 'Bearer ',
  tokenHeader: 'Authorization',
   dns: 'https://developapi.callwaiter.fr/api/',
   cdn: 'https://developapi.callwaiter.fr/',
   website: 'https://develop.callwaiter.fr/',
   webapp: 'https://preprodapp.guestwaiter.com/',

  //dns: 'https://api.guestwaiter.com/api/',
  //cdn: 'https://api.guestwaiter.com/',
  //website: 'https://guestwaiter.com/',
 //webapp: 'https://app.guestwaiter.com/',

  // dns: 'http://localhost:8000/api/',
  // cdn: 'http://localhost:8000/'
//  dns: 'https://stagingapi.callwaiter.fr/api/',
 // cdn: 'https://stagingapi.callwaiter.fr/',
 // website: 'https://staging.callwaiter.fr/',
//  webapp: 'https://stagingapp.guestwaiter.com/
};
//russell.keeling@example.org
//Azerty2266
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
